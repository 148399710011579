import React from "react";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../components/layout";

import BreadcrumbsFactory from "../features/Layout/Breadcrumbs/BreadcrumbsFactory";
const breadcrumbsFactory = new BreadcrumbsFactory();

const StyledContainer = styled.div``;

const Service = ({pageContext}) => {
  const { title, content, slug } = pageContext;
  // Создаем объекты для хлебных крошек
  const crumbs = breadcrumbsFactory.fromArray([
    {
      path: `/${slug}`,
      title,
    },
  ]);
  return (
    <Layout crumbs={ crumbs }>
    <SEO title={ title } />
      <StyledContainer className="container">
        <h1>{ title }</h1>
        <div dangerouslySetInnerHTML={{__html: content}}/>
      </StyledContainer>
    </Layout>
  );
};

export default Service;